<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">企业现场安全检查</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        安全检查是企业确保生产安全、业务稳定成长、规避最大风险的最基础的管理手段。景翔公司拥有一批服务于企业现场安全管理与危险源辨识和管控范的顾问团队，多年的项目实践经验，将企业各种现场安全管理的多样要素和疑难杂症，转化成检查题库。景翔公司的安全检查，可以有效覆盖现场安全所需的所有管理标准和要素，并采取随机抽取的方式，以评分或扣分的方式，客观、有依据、有证据（采取拍照上传）地评价被检查区域的管理水平，并发起不符合项的改善跟踪提醒、改善证据上传等，实现无纸化，系统平台连接手机终端的智能操作。
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/56.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/57.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/58.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/59.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">1）高效实现企业高频次的安全现场检查；</p>
      <p class="SmallP" style="padding: 0">
        2）实现从检查发起、实施，到检查记录填写，再到整改的证据上传到验证闭环的一体化系统；
      </p>
      <p class="SmallP">3）实现无纸化，系统平台连接手机终端的智能操作。</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>